import "./styles.scss"

import React from "react"
import Slider from "react-slick"
import { motion } from "framer-motion"

import AnimatedMask from "components/AnimatedMask"

import fadeAnimation from "utils/fadeAnimation"

import { useLocale } from "context/locale"

const Content = ({ pageData }) => {
  const { t } = useLocale()

  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  return (
    <section className="textures-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <motion.div
              dangerouslySetInnerHTML={{
                __html: pageData?.acfTexturesPage?.description,
              }}
              {...fadeAnimation}
            />
          </div>

          <div className="col-md-6 offset-lg-1">
            <motion.div className="textures-content__slider" {...fadeAnimation}>
              <Slider {...slider}>
                {pageData?.acfTexturesPage?.slider?.map((item, index) => (
                  <div key={index}>
                    <div
                      className="textures-content__item"
                      style={{
                        backgroundImage: `url('${item?.textureImage?.localFile?.publicURL}')`,
                      }}
                    >
                      <div>
                        <span>{t("Tekstura")}</span>
                        <h4>{item?.textureLabel}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <AnimatedMask />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
